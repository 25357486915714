import useIsMobile from "./hooks/useIsMobile"

const AboutMe = ({skillSet: {aboutMe, mobileAboutMe, hardSkills, softSkills}, profilePictureUrl, aboutMeProfilePictureUrl}) => {

    const {isMobile} = useIsMobile()

    const softSkillsDOM = <ul className="list-none">
        {softSkills.map(el => <li className="my-4 flex">
            <input readOnly type="checkbox" checked className="checkbox checkbox-primary"/>
            <div className="ml-2 badge badge-secondary">{el}</div>
        </li>)}
    </ul>

    const hardSkillsDOM =  <ul className="list-none">
    {hardSkills.map(el => <li className="my-4 flex">
        <input readOnly type="checkbox" checked className="checkbox checkbox-primary"/>
        <div className="ml-2 badge badge-secondary">{el.name}</div>
    </li>)}
</ul>

    return <div
        className={`card-side rounded-none lg:rounded-2xl card bg-gray-700 lg:w-5/6 h-full w-full lg:h-5/6`}>
        <figure>
            <div className="avatar hidden lg:block">
                <div className="w-36 lg:w-80 h-full w-2/6">
                    <img src={aboutMeProfilePictureUrl || profilePictureUrl} alt="Profile"/>
                </div>
            </div>
        </figure>
        <div className="card-body lg:w-4/6 bg-neutral text-primary">
            <h2 className="card-title">Di piú su Shotokan Sport</h2>
            <p className="flex-none">{isMobile ? mobileAboutMe : aboutMe}</p>
            {<>
                <div className="divider"/>
                <h2 className="card-title">I valori e le discipline di Shotokan Sport Club:</h2>
                <div className="flex-row w-full hidden lg:flex">
                    <div className="w-2/4">
                        <h4>Discipline:</h4>
                        {hardSkillsDOM}
                    </div>
                    <div className="w-2/4">
                        <h4>Valori:</h4>
                        {softSkillsDOM}
                    </div>
                </div>
                <div className="lg:hidden block overflow-auto">
                <div className="lg:hidden block">
                        <h2 className="mb-2">Discipline:</h2>
                        {hardSkills.map(el => <div className="mr-1 text-xs badge badge-accent">{el.name}</div>)}
                    </div>
                    <div className="lg:hidden block mt-2">
                        <h2 className="mb-2">Valori:</h2>
                        {softSkills.map(el => <div className="mr-1 text-xs badge badge-accent">{el}</div>)}
                    </div>
                </div>
            </>
            }
        </div>
    </div>
}
export default AboutMe
